<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col
                md="4"
                cols="12"
              >
                <brands />
              </b-col>
              <b-col
                md="4"
                cols="12"
              >
                <models />
              </b-col>
              <b-col
                md="4"
                cols="12"
              >
                <hardware />
              </b-col>
              <b-col
                md="4"
                cols="12"
              >
                <fuels />
              </b-col>
              <b-col
                md="4"
                cols="12"
              >
                <gears />
              </b-col>
              <b-col
                md="4"
                cols="12"
              >
                <colors />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <cc />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <model-year />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <chasis />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <engine />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <invoice-no />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <invoice-date />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <purchase-price is-readonly />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <sale-price />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <location />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <state />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <pool />
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <special-stock />
              </b-col>
              <b-col
                cols="12"
              >
                <stock-statuses />
              </b-col>
            </b-row>
          </b-card>
          <b-button
            variant="danger"
            @click="saveData"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Brands from '@/views/Sales/Stocks/Components/AddStock/Brands.vue'
import Models from '@/views/Sales/Stocks/Components/AddStock/Models.vue'
import Hardware from '@/views/Sales/Stocks/Components/AddStock/Hardware.vue'
import Fuels from '@/views/Sales/Stocks/Components/AddStock/Fuels.vue'
import Gears from '@/views/Sales/Stocks/Components/AddStock/Gears.vue'
import Colors from '@/views/Sales/Stocks/Components/AddStock/Colors.vue'
import Cc from '@/views/Sales/Stocks/Components/AddStock/Cc.vue'
import modelYear from '@/views/Sales/Stocks/Components/AddStock/Model_year.vue'
import Chasis from '@/views/Sales/Stocks/Components/AddStock/Chasis.vue'
import Engine from '@/views/Sales/Stocks/Components/AddStock/Engine.vue'
import invoiceNo from '@/views/Sales/Stocks/Components/AddStock/Invoice_no.vue'
import invoiceDate from '@/views/Sales/Stocks/Components/AddStock/Invoice_date.vue'
import purchasePrice from '@/views/Sales/Stocks/Components/AddStock/Purchase_price.vue'
import salePrice from '@/views/Sales/Stocks/Components/AddStock/Sale_price.vue'
import Location from '@/views/Sales/Stocks/Components/AddStock/Location.vue'
import State from '@/views/Sales/Stocks/Components/AddStock/State.vue'
import Pool from '@/views/Sales/Stocks/Components/AddStock/Pool.vue'
import specialStock from '@/views/Sales/Stocks/Components/AddStock/Special_stock.vue'
import StockStatuses from '@/views/Sales/Stocks/Components/AddStock/StockStatuses.vue'

export default {
  name: 'Edit',
  components: {
    StockStatuses,
    BCard,
    BRow,
    BCol,
    BButton,
    Brands,
    Models,
    Hardware,
    Fuels,
    Gears,
    Colors,
    Cc,
    modelYear,
    Chasis,
    Engine,
    invoiceNo,
    invoiceDate,
    purchasePrice,
    salePrice,
    Location,
    State,
    Pool,
    specialStock,
    ValidationObserver,
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/addLogoStock']
    },
    saveStatus() {
      return this.$store.getters['stock/getStockSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('stock/stock', this.$route.params.id)
        .then(res => {
          if (res.id_com_brand) {
            this.getModels(res.id_com_brand)
          }
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('stock/stockSave', this.dataItem)
        }
      })
    },
    getModels(id) {
      if (this.dataItem.id_com_brand) {
        this.$store.dispatch('models/modelsList', {
          where: {
            'com_model.id_com_brand': id,
          },
        })
      }
    },
  },
}
</script>
